import React from "react";

const HeroSection = ({ customerInformation }) => {
   const URL = window.location.pathname.replace("/", "")
   const payment_link = `https://secure.worldpay.com/wcc/purchase?instId=1421573&cartId=${customerInformation.payment_details.reference}&amount=${customerInformation.payment_details.amount}&currency=GBP&desc=${customerInformation.payment_details.reason}&testMode=0&lang=en&address1=${customerInformation.address.line_1.replace("None", "")}&town=${customerInformation.address.city}&address2=${customerInformation.address.line_2 || ''}&postcode=${customerInformation.address.postcode}&country=UK&email=${customerInformation.personal_information.email_address}&tel=${customerInformation.personal_information.phone_number}`;

   return (
      <React.Fragment>
         <div className={`hero-container ${URL}`}>
            <span>
               <div className={`logo-container ${URL}`} />

               <h1>Hi {customerInformation.personal_information.first_name}</h1>

               <p>To complete the requested payment, check all your information is correct, then proceed to payment.</p>

               <div className="payment-summary-window">
                  <h2>{parseFloat(customerInformation.payment_details.amount).toFixed(2)}</h2>

                  <span>
                     <h4>Reason for payment</h4>
                     <p>{customerInformation.payment_details.reason}</p>
                  </span>
               </div>
            </span>

            <div className="payment-link-container">
               <a
                  className="payment-link-button"
                  href={payment_link}
               >Proceed to payment</a>
            </div>
         </div>
      </React.Fragment>
   )
}

export default HeroSection