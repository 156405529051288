import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import HeroSection from "./parts/hero-section";
import DetailsSection from "./parts/details-section";

const Home = () => {
   const [customerInformation, updateCustomerInformation] = useState({
      personal_information: {
         first_name: '',
         last_name: '',
         email_address: '',
         phone_number: ''
      },
      address: {
         line_1: '',
         line_2: '',
         city: '',
         postcode: ''
      },
      payment_details: {
         service_number: '',
         amount: '',
         reference: '',
         method: 'Card',
         agent: '',
         date: (new Date().toLocaleDateString("en-GB")),
         reason: ''
      }
   })
   const [urlParams, setUrlParams] = useSearchParams();

   useEffect(() => {
      updateCustomerInformation({
         personal_information: {
            first_name: urlParams.get("firstName"),
            last_name: urlParams.get("lastName"),
            email_address: urlParams.get("email"),
            phone_number: urlParams.get("phone")
         },
         address: {
            line_1: `${urlParams.get("doornumber")} ${urlParams.get("addressline1")}`,
            line_2: urlParams.get("addressline2"),
            city: urlParams.get('city'),
            postcode: urlParams.get("postcode")
         },
         payment_details: {
            ...customerInformation.payment_details,
            service_number: urlParams.get("serviceNumber").toUpperCase(),
            amount: urlParams.get("amount"),
            reference: urlParams.get("cartId").toUpperCase(),
            agent: urlParams.get("agent"),
            reason: urlParams.get("paymentReason")
         }
      })
   }, [])

   return (
      <React.Fragment>
         <div className="body-container">
            <HeroSection
               customerInformation={customerInformation}
            />
            <DetailsSection
               customerInformation={customerInformation}
            />
         </div>
      </React.Fragment>
   )
}

export default Home