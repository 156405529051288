import React from "react";

const DetailsSection = ({ customerInformation }) => {
   const URL = window.location.pathname.replace("/", "")
   const address = customerInformation.address

   const card_images = [
      "visa",
      "mastercard",
      "maestro",
      "jcb"
   ]

   return (
      <React.Fragment>
         <div className={`content-container ${URL}`}>
            <h2>Your details</h2>

            <div className="table-wrapper-container">
               {/* Personal information trigger - XS */}
               <input
                  type="radio"
                  id="personal_information"
                  className="invisible xs-info-box-trigger"
                  name="details"
                  defaultChecked={true}
               />

               <table className={URL}>
                  <tbody>
                     <tr>
                        <th colspan="2">
                           <label className="pd-icon" htmlFor="personal_information">Personal information</label>
                        </th>
                     </tr>
                     <tr className="table-xs">
                        <td style={{ width: "80px" }}>Name:</td>
                        <td style={{ width: "auto" }}>{customerInformation.personal_information.first_name} {customerInformation.personal_information.last_name}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Phone:</td>
                        <td>{customerInformation.personal_information.phone_number}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Email:</td>
                        <td style={{ lineBreak: 'anywhere' }}>{customerInformation.personal_information.email_address}</td>
                     </tr>
                  </tbody>
               </table>

               {/* Address trigger - XS */}
               <input
                  type="radio"
                  id="address"
                  className="invisible xs-info-box-trigger"
                  name="details"
               />

               <table className={URL}>
                  <tbody>
                     <tr>
                        <th>
                           <label className="ad-icon" htmlFor="address">
                              Address
                           </label>
                        </th>
                     </tr>
                     <tr className="table-xs address">
                        <td>
                           {address.line_1.replace("None", "")}<br />
                           {address.line_2 !== null ? (
                              <React.Fragment>
                                 {address.line_2}
                                 <br />
                              </React.Fragment>
                           ) : null}
                           {address.city}<br />
                           {address.postcode}
                        </td>
                     </tr>
                  </tbody>
               </table>

               {/* Payment details trigger - XS */}
               <input
                  type="radio"
                  id="payment_details"
                  className="invisible xs-info-box-trigger"
                  name="details"
               />

               <table className={URL}>
                  <tbody>
                     <tr>
                        <th colspan="2">
                           <label className="py-icon" htmlFor="payment_details">
                              Payment details
                           </label>
                        </th>
                     </tr>
                     <tr className="table-xs">
                        <td style={{ width: "180px" }}>Service number:</td>
                        <td style={{ width: "auto" }}>{customerInformation.payment_details.service_number}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Amount to pay:</td>
                        <td>£{parseFloat(customerInformation.payment_details.amount).toFixed(2)}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Reference:</td>
                        <td>{customerInformation.payment_details.reference}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Payment reason:</td>
                        <td>{customerInformation.payment_details.reason}</td>
                     </tr>
                     <tr className="table-xs">
                        <td>Payment method:</td>
                        <td>{customerInformation.payment_details.method}</td>
                     </tr>
                     {/* <tr className="table-xs">
                        <td>Agent:</td>
                        <td>{customerInformation.payment_details.agent}</td>
                     </tr> */}
                     <tr className="table-xs">
                        <td>Date:</td>
                        <td>{customerInformation.payment_details.date}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <br />
            <br />


            <input
               type="checkbox"
               className="invisible info-box-trigger"
               id="show-cards"
            />
            <div className="info-box green">
               <label className="wrapper" htmlFor="show-cards">
                  <div className="inner-wrapper">
                     <span>
                        <h4>We accept most cards</h4>
                        <p>See all accepted providers</p>
                     </span>
                  </div>
               </label>

               <div className="show-card-types">
                  {card_images.map((card) => (
                     <img
                        src={`/images/${card}-logo.png`}
                        alt={`${card} logo`}
                        title={`${card} logo`}
                        className="card-type-logo"
                     />
                  ))}
               </div>
            </div>

            <br />


            <div className="info-box blue">
               <div className="wrapper">
                  <div className="inner-wrapper">
                     <span>
                        <h4>All payments are final</h4>
                        <p>We do not offer refunds, unless otherwise stated in your terms & conditions.</p>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment>
   )
}

export default DetailsSection