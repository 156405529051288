import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom'
import "./styles.css"
import Home from './components/home';

function App() {
  return (
    <React.Fragment>
      <Switch>
        {/* Smart Plan */}
        <Route
          exact
          path="/smart-plan"
          element={<Home/>}
        />

        {/* Appliance Sure */}
        <Route
          exact
          path="/appliance-sure"
          element={<Home/>}
        />

        {/* The Boiler Company */}
        <Route
          exact
          path="/the-boiler-company"
          element={<Home/>}
        />

        {/* Boiler Safe UK */}
        <Route
          exact
          path="/boiler-safe-uk"
          element={<Home/>}
        />
      </Switch>
    </React.Fragment>
  );
}

export default App;
